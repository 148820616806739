<template>
  <v-dialog
    v-model="showFullData"
    max-width="1200"
  >
    <v-card>
      <v-tabs
        v-model="tab"
        background-color="primary"
        centered
        dark
        icons-and-text
      >
        <v-tabs-slider />

        <v-tab href="#personData">
          Dados Pessoais
          <v-icon>mdi-account</v-icon>
        </v-tab>

        <v-tab href="#addressesData">
          Endereços
          <v-icon>mdi-map-marker</v-icon>
        </v-tab>

        <v-tab href="#photosData">
          Documentos
          <v-icon>mdi-text-box</v-icon>
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item
          key="personData"
          value="personData"
        >
          <v-card flat>
            <v-card-text>
              <v-card outlined>
                <v-card-text class="pa-2">
                  <ul
                    style="list-style: none;"
                    class="pl-1"
                  >
                    <li>
                      <strong>Nome:&nbsp;</strong>
                      <span>{{ personData.nome || 'Não Informado' }}</span>
                    </li>
                    <li>
                      <strong>Nascimento:&nbsp;</strong>
                      <span>{{ personData.nascimento || 'Não Informado' | date }}</span>
                    </li>
                    <li>
                      <strong>Idade:&nbsp;</strong>
                      <span>{{ personData.dataNascimento || 'Não Informado' | idade }}</span>
                    </li>
                    <li>
                      <strong>Nº Identidade:&nbsp;</strong>
                      <span>{{ personData.identidade || 'Não Informado' }}</span>
                    </li>
                    <li>
                      <strong>CPF:&nbsp;</strong>
                      <span>{{ personData.cpf || 'Não Informado' }}</span>
                    </li>
                    <li>
                      <strong>E-mail:&nbsp;</strong>
                      <span>{{ personData.email || 'Não Informado' }}</span>
                    </li>
                    <li>
                      <strong>Celular:&nbsp;</strong>
                      <span>{{ personData.contatoSMS || 'Não Informado' }}</span>
                    </li>
                    <li>
                      <strong>Nome do Pai:&nbsp;</strong>
                      <span>{{ personData.nomeDoPai || 'Não Informado' }}</span>
                    </li>
                    <li>
                      <strong>Nome da Mãe:&nbsp;</strong>
                      <span>{{ personData.nomeDaMae || 'Não Informado' }}</span>
                    </li>
                    <li>
                      <strong>Conjuge:&nbsp;</strong>
                      <span>{{ personData.conjuge || 'Não Informado' }}</span>
                    </li>
                  </ul>
                </v-card-text>
              </v-card>
            </v-card-text>
          </v-card>
        </v-tab-item>

        <v-tab-item
          key="addressesData"
          value="addressesData"
        >
          <v-card flat>
            <v-card-text>
              <v-card outlined>
                <v-card-title class="text-subtitle-1 pa-2">
                  <strong>Endereço de Entrega</strong>
                </v-card-title>
                <v-card-text class="pa-2">
                  <ul
                    style="list-style: none;"
                    class="pl-1"
                  >
                    <li>
                      <strong>CEP:&nbsp;</strong>
                      <span>{{ billing.cep }}</span>
                    </li>
                    <li>
                      <strong>Cidade:&nbsp;</strong>
                      <span>{{ billing.cidade.nome }} - {{ billing.cidade.estado.uf }}</span>
                    </li>
                    <li>
                      <strong>Bairro:&nbsp;</strong>
                      <span>{{ billing.bairro }}</span>
                    </li>
                    <li>
                      <strong>Endereço:&nbsp;</strong>
                      <span>{{ billing.logradouro }}, {{ billing.numero }}</span>
                    </li>
                  </ul>
                </v-card-text>
              </v-card>
              <br>
              <v-card outlined>
                <v-card-title class="text-subtitle-1 pa-2">
                  <strong>Endereço de Cobrança</strong>
                </v-card-title>
                <v-card-text class="pa-2">
                  <ul
                    style="list-style: none;"
                    class="pl-1"
                  >
                    <li>
                      <strong>CEP:&nbsp;</strong>
                      <span>{{ shipping.cep }}</span>
                    </li>
                    <li>
                      <strong>Cidade:&nbsp;</strong>
                      <span>{{ shipping.cidade.nome }} - {{ shipping.cidade.estado.uf }}</span>
                    </li>
                    <li>
                      <strong>Bairro:&nbsp;</strong>
                      <span>{{ shipping.bairro }}</span>
                    </li>
                    <li>
                      <strong>Endereço:&nbsp;</strong>
                      <span>{{ shipping.logradouro }}, {{ shipping.numero }}</span>
                    </li>
                  </ul>
                </v-card-text>
              </v-card>
            </v-card-text>
          </v-card>
        </v-tab-item>

        <v-tab-item
          key="photosData"
          value="photosData"
        >
          <v-card flat>
            <v-card-text>
              <v-card outlined>
                <v-card-text class="pa-2">
                  <v-expansion-panels>
                    <v-expansion-panel
                      v-for="file in creditFilesData"
                      :key="file.creditRequestFileId"
                    >
                      <v-expansion-panel-header>
                        {{ file.descriptionTypeFile }}
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-row
                          justify="center"
                        >
                          <v-col cols="12">
                            Se estiver com problemas para visualizar o documento
                            <a
                              :href="file.urlFile"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <v-chip
                                class="m-0 p-0 st-chip"
                                color="green"
                                text-color="white"
                              >
                                clique aqui
                              </v-chip>
                            </a>
                            .
                          </v-col>
                          <template v-if="isPDF(file.fileKeyAmazon)">
                            <embed
                              :src="file.urlFile"
                              width="100%"
                              height="500"
                              alt="pdf"
                              pluginspage="http://www.adobe.com/products/acrobat/readstep2.html"
                            >
                          </template>
                          <img
                            v-else
                            :src="file.urlFile"
                            class="doc-img"
                          >
                          <!-- <v-img
                            max-width="500"
                            :src="file.urlFile"
                          /> -->
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-card-text>
              </v-card>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    props: {
      show: { type: Boolean },
      personData: { type: Object, default: () => {} },
      addressesData: { type: Object, default: () => {} },
      creditFilesData: { type: Array, default: () => [] },
    },
    data: () => ({
      showFullData: false,
      tab: 'personData',
    }),
    computed: {
      shipping () {
        return this.addressesData && this.addressesData.shipping
          ? this.addressesData.shipping
          : { cidade: { estado: {} } }
      },
      billing () {
        return this.addressesData && this.addressesData.billing
          ? this.addressesData.billing
          : { cidade: { estado: {} } }
      },
    },
    watch: {
      show (v) {
        this.showFullData = v
      },
      showFullData (v) {
        if (v === false) {
          this.$emit('onClose')
          this.tab = 'personData'
        }
      },
    },
    methods: {
      async getBlobFromUrl (url) {
        const headers = new Headers()
        const init = {
          method: 'GET',
          headers: headers,
          mode: 'cors',
        }
        const request = new Request(url, init)
        const blob = await fetch(request).then(r => r.blob())
        const fileObjectURL = URL.createObjectURL(blob)
        window.open(fileObjectURL)
      },
      isPDF (url) {
        return url.split('.')[url.split('.').length - 1].toLowerCase() === 'pdf'
      },
    },
  }
</script>
<style>
.doc-img {
  max-width: 100%;
}
a {
  text-decoration: none;
  cursor: pointer;
}
.st-chip {
  cursor: pointer;
}
</style>
