<template>
  <v-container fluid>
    <v-col
      cols="12"
      sm="12"
      md="12"
    >
      <v-card width="100%">
        <v-card-title>
          <v-text-field
            v-model="filter.Nome"
            label="Buscar (nome)"
            single-line
            hide-details
            class="mr-5"
            @keyup.enter="load"
          />
          <v-text-field
            v-model="filter.cpf"
            label="Buscar (cpf)"
            single-line
            hide-details
            class="mr-5"
            @keyup.enter="load"
          />
          <v-text-field
            v-model="filter.codigoStyllus"
            label="Buscar (código styllus)"
            single-line
            hide-details
            type="number"
            @keyup.enter="load"
          />
          <v-spacer />
          <v-btn
            color="primary"
            @click="load"
          >
            Buscar
          </v-btn>
        </v-card-title>
      </v-card>
    </v-col>
    <v-data-table
      :headers="headers"
      :items="creditRequests"
      :options.sync="options"
      :server-items-length="total"
      class="elevation-1"
      :footer-props="footer_props"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Solicitações de Crédito</v-toolbar-title>
        </v-toolbar>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.cliente.pessoa.nascimento="{ item }">
        {{ item.cliente.pessoa.nascimento | date }} - {{ item.cliente.pessoa.nascimento | idade }}
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.createdAt="{ item }">
        {{ item.createdAt | timestamp }}
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.requestStatusTexto="{ item }">
        <v-chip
          :color="getColor(item.requestStatus)"
          dark
        >
          {{ item.requestStatusTexto }}
          {{ item.priority ? ' (PRIORITÁRIO)' : '' }}
        </v-chip>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.view="{ item }">
        <v-btn
          text
          small
          @click.stop="onShowFullData(item)"
        >
          <v-icon class="primary--text">
            mdi-eye
          </v-icon>
        </v-btn>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.actions="{ item }" class="text-center">
        <div
          v-if="item.requestStatus === StatusEnum.EmAnalise"
        >
          <v-btn
            text
            small
            @click.stop="accepted(item)"
          >
            <v-icon class="success--text">
              mdi-thumb-up
            </v-icon>
          </v-btn>
          <v-btn
            text
            small
            @click.stop="rejected(item)"
          >
            <v-icon class="error--text">
              mdi-thumb-down
            </v-icon>
          </v-btn>
          <v-btn
            text
            small
            @click.stop="askForUpdate(item)"
          >
            <v-icon class="error--text">
              mdi-refresh
            </v-icon>
          </v-btn>
        </div>
        <v-chip
          v-else
          :color="getColor(item.requestStatus)"
          dark
        >
          {{ item.requestStatusTexto }}
        </v-chip>
      </template>
    </v-data-table>
    <v-dialog
      v-model="showAcceptedCredit"
      persistent
      max-width="320"
    >
      <v-card class="pa-4">
        <v-card-title class="text-subtitle-1 pa-2">
          Aprovação de Crédito
        </v-card-title>
        <v-card-text class="pb-0 px-2 mt-4">
          <v-form
            ref="form"
            lazy-validation
          >
            <v-text-field
              v-model="request.analysisCode"
              label="Código da Análise"
              :rules="rules"
              outlined
            />
            <v-text-field
              v-model="request.creditValue"
              type="number"
              label="Valor do Crédito"
              :rules="rulesValor"
              outlined
            />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            outlined
            @click="onClose"
          >
            Fechar
          </v-btn>
          <v-btn
            color="primary"
            :disabled="!request.creditValue"
            @click="onSubmit"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showRejectedCredit"
      persistent
      max-width="320"
    >
      <v-card class="pa-4">
        <v-card-title class="text-subtitle-1 pa-2">
          {{ rejecttext }}
        </v-card-title>
        <v-card-text class="pb-0 px-2 mt-4">
          <v-form
            ref="form"
            lazy-validation
          >
            <v-text-field
              v-if="!ilegivel"
              v-model="request.analysisCode"
              label="Código da Análise"
              :rules="rules"
              outlined
            />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            outlined
            @click="onClose"
          >
            Não
          </v-btn>
          <v-btn
            color="primary"
            @click="onSubmit"
          >
            Sim
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <full-data
      :show="showFullData"
      :person-data="personData"
      :addresses-data="addressesData"
      :credit-files-data="creditFilesData"
      @onClose="showFullData = false"
    />
  </v-container>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { StatusEnum } from '../status.enum.js'
  import FullData from './FullData'

  export default {
    components: {
      FullData,
    },
    data: () => ({
      StatusEnum,
      analiseAprovada: 1,
      creditRequests: [],
      headers: [
        { text: 'Código Styllus', value: 'cliente.codigoStyllus' },
        { text: 'Nome', value: 'cliente.pessoa.nome' },
        { text: 'Data de Nascimento', value: 'cliente.pessoa.nascimento' },
        { text: 'CPF', value: 'cliente.pessoa.cpf' },
        { text: 'Data da Solicitação', value: 'createdAt' },
        { text: 'Status da Solicitação', value: 'requestStatusTexto', align: 'center' },
        { text: 'Analisado por', value: 'quemAprovou', align: 'center', sortable: false },
        { text: 'Dados Completos', value: 'view', align: 'center', sortable: false },
        { text: 'Aprovar?', value: 'actions', align: 'center', sortable: false },
      ],
      footer_props: {
        'items-per-page-options': [5, 10, 15, 20],
      },
      total: 0,
      options: {
        sortBy: [],
        sortDesc: [],
      },
      filter: {
        PerPage: 25,
        Page: 1,
      },
      showAcceptedCredit: false,
      showRejectedCredit: false,
      showFullData: false,
      ilegivel: false,
      request: {},
      itemSelected: {},
      personData: {},
      addressesData: {},
      creditFilesData: [],
      creditrequest: [],
      successSubmit: {
        title: 'Solicitação salva',
        text: 'Tudo certo ;)',
        type: 'success',
      },
      rules: [v => !!v || 'Campo é obrigatório!'],
      rulesValor: [
        v => !!v || 'Campo é obrigatório!',
        v => (v && v <= 10000) || 'Limite máximo R$ 10.000,00',
        v => (v && v >= 0) || 'Limite de crédito não pode ser negativo.',
      ],
    }),
    computed: {
      ...mapGetters([
        'totalLinhas',
        'totalPaginas',
      ]),
      rejecttext () {
        return this.ilegivel ? 'Deseja solicitar o reenvio?' : 'Deseja realmente recusar o crédito?'
      },
    },
    watch: {
      options: {
        handler () {
          this.load()
        },
        deep: true,
      },
    },
    methods: {
      load () {
        this.filter.PerPage = this.options.itemsPerPage
        this.filter.Page = this.options.page
        if (this.options.sortBy[0]) {
          this.filter.orderBy = this.options.sortBy[0]
          this.filter.sortDesc = this.options.sortDesc[0]
        }
        const query = JSON.stringify(this.filter)
        this.$http.get('/solicitacao-credito', { params: { query } })
          .then(resp => {
            this.creditRequests = resp.data.rows
            this.total = resp.data.total
          })
          .catch(() => {
            this.$toast.error('Falha ao realizar operação')
          })
      },
      getColor (statusId) {
        switch (statusId) {
          case StatusEnum.EmAnalise:
            return 'warning'
          case StatusEnum.Aprovado:
            return 'success'
          case StatusEnum.Recusado:
            return 'error'
          default:
            return 'primary'
        }
      },
      accepted (item) {
        this.itemSelected = item
        this.analiseAprovada = 2
        this.showAcceptedCredit = true
      },
      rejected (item) {
        this.itemSelected = item
        delete this.itemSelected.creditValue
        this.analiseAprovada = 3
        this.showRejectedCredit = true
      },
      askForUpdate (item) {
        this.itemSelected = item
        delete this.itemSelected.creditValue
        this.analiseAprovada = 4
        this.showRejectedCredit = true
        this.ilegivel = true
      },
      onRejected () {
        this.showRejectedCredit = false
        this.ilegivel = false
        this.itemSelected.requestStatus = StatusEnum.Recusado
        this.itemSelected.analysisCode = this.request.analysisCode
        delete this.itemSelected.creditValue
        this.$store.dispatch('updateCreditRequest', this.itemSelected).then(data => {
          this.$toast.success('Operação realizada com sucesso')
        })
        this.load()
      },
      onClose () {
        this.itemSelected = {}
        this.personData = {}
        this.addressesData = {}
        this.request = {}
        this.creditFilesData = []
        this.showAcceptedCredit = false
        this.showRejectedCredit = false
        this.showFullData = false
        this.ilegivel = false
        this.load()
      },
      onShowFullData (itemSelected) {
        this.personData = itemSelected.cliente.pessoa
        this.addressesData = {
          shipping: itemSelected.cliente.shipping,
          billing: itemSelected.cliente.billing,
        }

        this.creditFilesData = itemSelected.creditRequestFiles

        this.showFullData = true
      },
      onSubmit () {
        if (!this.$refs.form.validate()) {
          return
        }
        this.showAcceptedCredit = false
        this.request.creditRequestId = this.itemSelected.creditRequestId
        this.request.requestStatus = this.analiseAprovada
        if (!this.request.creditValue) this.request.creditValue = 0
        this.$store.dispatch('updateCreditRequest', this.request).then(data => {
          this.$toast.success('Operação realizada com sucesso')
          this.onClose()
        })
      },
    },
  }
</script>
